@import url("@firi/ui-web/styles/OpenSauceOne.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6, a {
    @apply text-firi-blue;
  }

  html,
  body {
    /* @apply text-landing-sm lg:text-landing-md;  */
    @apply text-black-700 font-sans;
    /* scroll-behavior: smooth; */
  }

  table thead {
    @apply text-left;
  }

  table th, table td {
    @apply px-2 border border-black-200;
  }

  .cms ol {
    list-style: none;
    counter-reset: cms-counter;
    @apply space-y-10;
  }

  .cms ol li {
    counter-increment: cms-counter;
    @apply rs-landing-md
  }

  .cms ol li::before {
    content: counter(cms-counter);
    @apply bg-firi-blue h-8 w-8 rounded-full text-white flex-shrink-0 mr-2 lg:mr-6 items-center justify-center inline-flex;
    @apply  lg:w-10 lg:h-10;
  }


  .cms ul {
    @apply space-y-6 ml-6 mt-4;
  }

   .cms ul li {
    @apply items-baseline rs-landing-md relative;
  } 

  .cms ul li::before {
    content: "";
    @apply w-2 h-2 bg-firi-blue rounded-full absolute -left-5 top-2 lg:top-3;
  }

}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.BambooHR-ATS-board h2 {
  @apply hidden;
}

.BambooHR-ATS-board .BambooHR-ATS-Jobs-List {
  @apply !px-0;
}

@keyframes fly-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fade-in {
  0%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fly-in {
  animation-name: fly-in;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

.animate-fade-in {
  animation-name: fade-in, fly-in;
  animation-duration: 0.8s, 1s;
  animation-timing-function: ease-in-out, ease-in-out;
  animation-fill-mode: forwards;
}

.snowflake {
  color: #fff;
  font-size: 14px;
  font-family: Arial, sans-serif;
  z-index: -1;
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -5%;
  }
  100% {
    top: 100%;
  }
}
@-webkit-keyframes snowflakes-shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
}
@keyframes snowflakes-fall {
  0% {
    top: -5%;
  }
  100% {
    top: 100%;
  }
}
@keyframes snowflakes-shake {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(80px);
  }
}
.snowflake {
  position: absolute;
  top: -5%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
}
.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}
.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s;
}
.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, 0.5s;
  animation-delay: 6s, 0.5s;
}
.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s;
}
.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s;
}
.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s;
}
.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s;
}
.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s;
}
.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s;
}
.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s;
}
.snowflake:nth-of-type(10) {
  left: 25%;
  -webkit-animation-delay: 2s, 0s;
  animation-delay: 2s, 0s;
}
.snowflake:nth-of-type(11) {
  left: 65%;
  -webkit-animation-delay: 4s, 2.5s;
  animation-delay: 4s, 2.5s;
}
