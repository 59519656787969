@font-face {
  font-family: 'OpenSauceOne';
  src: url('../../shared/assets/fonts/web/OpenSauceOne-Black.ttf')
    format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../../shared/assets/fonts/web/OpenSauceOne-BlackItalic.ttf')
    format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../../shared/assets/fonts/web/OpenSauceOne-ExtraBold.ttf')
    format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../../shared/assets/fonts/web/OpenSauceOne-ExtraBoldItalic.ttf')
    format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../../shared/assets/fonts/web/OpenSauceOne-Bold.ttf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../../shared/assets/fonts/web/OpenSauceOne-BoldItalic.ttf')
    format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSauceOne';
  src:  url('../../shared/assets/fonts/web/OpenSauceOne-Medium.ttf')
  format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../../shared/assets/fonts/web/OpenSauceOne-MediumItalic.ttf')
  format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../../shared/assets/fonts/web/OpenSauceOne-Regular.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../../shared/assets/fonts/web/OpenSauceOne-Italic.ttf')
    format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../../shared/assets/fonts/web/OpenSauceOne-Light.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../../shared/assets/fonts/web/OpenSauceOne-LightItalic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../../shared/assets/fonts/web/OpenSauceOne-Light.ttf')
    format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSauceOne';
  src: url('../../shared/assets/fonts/web/OpenSauceOne-LightItalic.ttf')
    format('truetype');
  font-weight: lighter;
  font-style: italic;
}
